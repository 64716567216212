const projects = [
    {
        areaSolve: 'Construcción',
        area: 'Construction',
        client: 'Ludwig Van Oosterzee',
        clientLogo: null,
        backgroundImg: 'https://images.pexels.com/photos/2089698/pexels-photo-2089698.jpeg?auto=compress&cs=tinysrgb&w=1920&h=1080&dpr=1',
        houseType: 'Personalizado',
        reviewEs: `lorem-Es`,
        reviewEn: `lorem-En`,
        info: 'Proyecto de 4 habitaciones con estructura de MetalCon, Marbella',
    },
    {
        areaSolve: 'Construcción',
        area: 'Construction',
        client: 'Ruth Pilar',
        clientLogo: null,
        backgroundImg: '',
        houseType: 'Casa 2 pisos tipo 1',
        reviewEs: `loremEs`,
        reviewEn: `lorem-En`,
        info: 'Casa costa, 120m2 con estructura metalcon y revestimiento en madera de la zona',
    },
    {
        areaSolve: 'Construcción',
        area: 'Construction',
        client: 'Gallardo',
        clientLogo: null,
        backgroundImg: '',
        houseType: 'Personalizado',
        reviewEs: `loremEs`,
        reviewEn: `lorem-En`,
        info: '',
    },
    {
        areaSolve: 'Construcción',
        area: 'Construction',
        client: 'Silvia',
        clientLogo: null,
        backgroundImg: '',
        houseType: 'Casa tipo 2',
        reviewEs: `loremEs`,
        reviewEn: `lorem-En`,
        info: '',
    },
    {
        areaSolve: 'Construcción',
        area: 'Construction',
        client: 'Bellolio',
        clientLogo: null,
        backgroundImg: '',
        houseType: 'Casa tipo 1',
        reviewEs: `loremEs`,
        reviewEn: `lorem-En`,
        info: '',
    },
    {
        areaSolve: 'Ingeniería',
        area: 'Engineering',
        client: 'TeslaInc',
        clientLogo: 'https://imgs.search.brave.com/olBW6cZgw0vhXH7CC17HJ3TuMotJ9VjoMBNPlGxcu2Y/rs:fit:860:0:0/g:ce/aHR0cHM6Ly91cGxv/YWQud2lraW1lZGlh/Lm9yZy93aWtpcGVk/aWEvY29tbW9ucy9i/L2JkL1Rlc2xhX01v/dG9ycy5zdmc.svg',
        backgroundImg: 'https://imgs.search.brave.com/pbZ8tsKx2H7X6nF-Pesmm7-m3hxDZN9kg0nFlPrs8ao/rs:fit:860:0:0/g:ce/aHR0cHM6Ly91cGxv/YWQud2lraW1lZGlh/Lm9yZy93aWtpcGVk/aWEvY29tbW9ucy81/LzU3L1Rlc2xhX2F1/dG9fYm90cy5qcGc',
        houseType: 'Calculo estructural y levantamiento de planos',
        reviewEs: `loremEs`,
        reviewEn: `lorem-En`,
        info: 'Levantamiento de estructura, cálculo estructural de montaje y ensamblado',
    },
    {
        areaSolve: 'Ingeniería',
        area: 'Engineering',
        client: 'CocaKola',
        clientLogo: 'https://imgs.search.brave.com/DJ_U_8hsE8qrx0tEo2AaoLNo2PBsEXxRptVByq7enXU/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9icmFu/ZHNsb2dvcy5jb20v/d3AtY29udGVudC91/cGxvYWRzL2ltYWdl/cy9jb2NhLWNvbGEt/bG9nby5wbmc',
        backgroundImg: 'https://imgs.search.brave.com/b5cDblUEwyljlrQOT1-An9VnvkZXfVWw_J2m5p6KYvw/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9tZWRp/YS5nZXR0eWltYWdl/cy5jb20vaWQvMTMx/MjcwNjQxMy9waG90/by9tb2Rlcm4taG9z/cGl0YWwtYnVpbGRp/bmcuanBnP3M9NjEy/eDYxMiZ3PTAmaz0y/MCZjPW9VSUxza210/YVBpQTcxMURQNTNE/RmhPVXZFN3BmZE5l/RUs5Q2Z5eGxHaW89',
        houseType: 'Calculo estructural y diseño de planos',
        reviewEs: `loremEs`,
        reviewEn: `lorem-En`,
        info: 'Levantamiento de estructura, cálculo estructural de oficinas de análisis de datos',
    },
]

export { projects }