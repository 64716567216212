export const headerLanguage = {
    Home_Es: 'Home',
    Home_En: 'Home',
    Engineering_Es: 'Ingeniería',
    Engineering_En: 'Engineering',
    Constuction_Es: 'Constucción',
    Constuction_En: 'Constuction',
    Architecture_Es: 'Arquitectura',
    Architecture_En: 'Architecture',
    Contact_Es: 'Contacto',
    Contact_En: 'Contact',
}